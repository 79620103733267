<template>
  <v-container>
    <v-stepper v-model="e1">
      <v-stepper-header>
        <v-stepper-step :complete="e1 > 1" step="1">
          Identificación de la empresa
        </v-stepper-step>
        <v-stepper-step :complete="e1 > 2" step="2">
          Datos de cotización
        </v-stepper-step>
        <v-stepper-step :complete="e1 > 3" step="3">
          Revision y envío de cotización
        </v-stepper-step>
        <v-stepper-step step="4">
          Finalizado
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card>
            <v-row>
              <!-- //  * campos de identificacion empresa cotizadora -->
              <v-col md="3">
                <v-text-field
                  v-mask="['X.XXX.XXX-X', 'XX.XXX.XXX-X']"
                  v-model="empresa.rut"
                  readonly
                  prepend-inner-icon="mdi-card-account-details-outline"
                  label="Rut empresa"
                />
              </v-col>
              <v-col md="7">
                <v-text-field
                  v-model="empresa.nombre"
                  readonly
                  label="Nombre Empresa"
                  prepend-inner-icon="mdi-account-settings-outline"
                />
              </v-col>
            </v-row>
            <v-row>
              <!-- // * campo de identificacion de quien esta registrando la cotizacion y de quien la solicito en planta -->
              <v-col md="5">
                <v-text-field
                  v-model="rcoti"
                  label="Responsable cotización"
                  hint="Ingrese el nombre de quien esta registrando esta cotización"
                  prepend-inner-icon="mdi-account-settings-outline"
                />
              </v-col>
              <!-- // * campo solicitante -->
              <v-col md="5">
                <v-select
                  v-model="solicitante"
                  :items="solicitantes"
                  label="Solicitante"
                  persistent-hint
                  hint="Seleccione el nombre de quien solicitó el trabajo"
                  prepend-inner-icon="mdi-account-settings-outline"
                />
              </v-col>
            </v-row>
            <v-card-actions>
              <!-- // * boton para seguir al paso siguiente -->
              <v-btn color="primary" :disabled="tostep2" @click="e1 = 2">
                Siguiente
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="2">
          <v-card>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="concept"
                  label="Describa el trabajo solicitado"
                  maxlength="100"
                  counter="100"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="3">
                <v-file-input
                  v-model="files"
                  label="Añadir cotización"
                  :show-size="1024"
                  prepend-icon="mdi-paperclip"
                  :rules="file_rules"
                >
                  <template v-slot:selection="{ text }">
                    <v-chip small label color="primary">
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input> </v-col
              ><v-col sm="3">
                <v-text-field
                  v-model="cost"
                  label="Costo"
                  permanent-hint
                  hint="ingresar costo total de la cotizacion"
                  @change="formatValor()"
                /> </v-col
              ><v-col sm="6">
                <v-file-input
                  v-model="opt_files"
                  color="deep-purple accent-4"
                  counter
                  label="Añadir documentacion adicional"
                  multiple
                  prepend-icon="mdi-paperclip"
                  :show-size="1024"
                  truncate-length="10"
                  :rules="files_rules"
                >
                  <template v-slot:selection="{ index, text }">
                    <v-chip v-if="index < 2" color="primary" label small>
                      {{ text }}
                    </v-chip>
                    <span
                      v-else-if="index === 2"
                      class="text-overline grey--text text--darken-3 mx-2"
                    >
                      + {{ opt_files.length - 2 }} Archivo(s)
                    </span>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
            <v-card-actions>
              <!-- boton volver atras -->
              <v-btn color="primary" @click="e1 = 1">
                Atrás
              </v-btn>
              <!-- <v-spacer/> -->
              <!-- boton para seguir al paso siguiente -->
              <v-btn color="success" :disabled="tostep3" @click="e1 = 3">
                Siguiente
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="3">
          <v-card>
            <v-card-title>Resumen de la cotizacion</v-card-title>´
            <v-card-text>
              <div>
                Se realizará el registro de la cotizacion con los siguientes
                datos:
                <br />
                <br /><b>- Empresa:</b> {{ empresa.nombre }} <br /><b
                  >- Rut Empresa:</b
                >
                {{ empresa.rut }} <br />- Encargado cotizacion: {{ rcoti }}
                <br />- Solicitante: {{ solicitante }}
                <br />
                <br />- Concepto cotizacion: {{ concept }} <br />- Costo total
                cotizacion: {{ cost }} <br />- Cotizacion adjunta:
                {{ cotname }} <br />- Documentación adjunta:
                {{ othernames }}
              </div>
            </v-card-text>
            <v-card-actions>
              <!-- boton volver atras -->
              <v-btn color="primary" @click="e1 = 2">
                Atrás
              </v-btn>
              <!-- <v-spacer /> -->
              <!-- boton para seguir al paso siguiente -->
              <v-btn color="success" :disabled="tostep4" @click="e1 = 4">
                Enviar cotización
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="4">
          <v-card>
            <v-col>
              La cotizacion ha sido registrada y notificada al solicitante.
            </v-col>
            <v-card-actions>
              <!-- <v-spacer/> -->
              <!-- boton para seguir al paso siguiente -->
              <v-btn color="success" @click="(e1 = 1), rstform()">
                Finalizar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
export default {
  name: "cotizaciones",

  data: () => ({
    e1: 1,
    empresa: {
      rut: "78594621-7",
      nombre: "Torneros asociados limitada",
    },
    nombre: "",
    rcoti: undefined,
    solicitante: undefined,
    solicitantes: [
      "solicitante 1",
      "solicitante 2",
      "solicitante 3",
      "solicitante 4",
    ],
    concept: "",
    file_rules: [
      (value) =>
        !value || value.size < 2e6 || "El tamaño máximo admitido es 2MB",
    ],
    files: undefined,
    files_rules: [
      (files) =>
        !files ||
        !files.some((file) => file.size > 2e6) ||
        "El tamaño máximo admitido por archivo es de 2MB",
    ],
    opt_files: [],
    cost: 0,
  }),
  watch: {
    cost: function() {
      console.log("lo excribi mal?");
    },
  },
  computed: {
    tostep2() {
      return false;
    },
    tostep3() {
      return false;
    },
    tostep4() {
      return false;
    },
    cotname() {
      return this.files ? this.files.name : "";
    },
    othernames() {
      return this.opt_files
        ? this.opt_files.map((el) => el.name).toString()
        : "";
    },
  },
  methods: {
    formatValor() {
      this.cost = Intl.NumberFormat("es-CL", {
        style: "currency",
        currency: "CLP",
      }).format(this.cost.replace(/\D/g, ""));
    },
  },
  mounted() {},
};
</script>
